<template>
  <div class="files">
    <span>Изображения</span>
    <hr>
    <v-file-input
      v-model="files"
      counter
      label="Выберите изображения"
      prepend-icon="mdi-paperclip"
      outlined
      :show-size="1000"
      dense
      multiple
    >
    <template v-slot:selection="{ index, text }">
      <v-chip
        v-if="index < 2"
        dark
        label
        small
      >
        {{ text }}
      </v-chip>

      <span
        v-else-if="index === 2"
        class="overline grey--text mx-2"
      >
        +{{ files.length - 2 }} Файл(ов)
      </span>
    </template>
  </v-file-input>
  <v-btn
    small
    @click="uploadFile()"
    color="primary"
    :disabled="!files.length"
  >Загрзить</v-btn>
  <v-btn
    small
    @click="allActiveToggle()"
    :color="allActive ? '#228B22' : '#FF4500'"
    :disabled="!images.length"
  >{{!allActive ? 'Выбрать все' : 'Снять все'}}</v-btn>
  <v-btn
    small
    color="red"
    :disabled="!selected.length"
    @click="deleteImg()"
  >
    {{selected.length ? `Удалить (${selected.length})` : 'Удалить'}}
  </v-btn>
  <div class="selected-img">
    <strong>Выбрано для удаления: <span>{{!selected.length ? 'нет' : selected.length + ' файл(ов)'}}</span></strong>
  </div>

  <v-card>
    <v-item-group
      v-model="selected"
      multiple
      class="img-grid"
    >
    <div 
      class="img-item"
      v-for="(img, i) in images"
      :key="i"
    >
    <v-item v-slot:default="{ active, toggle }" :value="img">
      <v-img
        :src="img.path + img.img" 
        @click="toggle"
      >
        <v-btn
          icon
          dark
        >
          <v-icon color="red">
            {{ active ? 'mdi-delete' : '' || active && allActive ? 'mdi-delete' : ''}}
          </v-icon>
        </v-btn>
      </v-img>
    </v-item>
    </div>
    </v-item-group>
  </v-card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
  props: {
    messageOn: Function,
    loadingWindow: Function,
    prodID: Number
  },
  data: () => ({
    files: [],
    selected: [],
    allActive: false,
  }),
  computed: {
    ...mapGetters(['getImages']),
    images: function() {
      this.selected = []
      return this.getImages.filter(i => i.item_id == this.prodID)
    }
  },
  methods: {
    ...mapActions(['uploadFiles', 'deleteFiles']),
    async uploadFile(){
      this.loadingWindow(true)
      const res = await this.uploadFiles({item_id: this.prodID, files: this.files})
      this.files = []
      this.loadingWindow(false)
      if(res.uploadProdIMG == 'ok') {
        this.messageOn('Изображения загружены', 'green')
      } else {
        this.messageOn('[ошибка:] Изображения не загружены', 'red')
      }
    },
    allActiveToggle() {
      this.allActive = !this.allActive
      if(this.allActive) {
        this.selected = this.images
        this.messageOn('Выбраны все изображения', 'red')
      } else if (!this.allActive) {
        this.allActive = false
        this.selected = []
        this.messageOn('Нет выбранных изображений', 'green')
      }
    },
    async deleteImg() {
      this.loadingWindow(true)
      let res = await this.deleteFiles(this.selected)
      this.loadingWindow(false)
      if(res.deleteProdIMG == 'ok') {
        this.messageOn('Изображения удалены', 'green')
      } else {
        this.messageOn('[ошибка:] Изображения не удалены', 'red')
      }
    }
  },
  watch: {
    selected: function() {
      if (!this.selected.length) {
        this.allActive = false
      }
      if (this.selected.length == this.images.length && this.images.length != 0) {
        this.allActive = true
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .files {
    margin-top: 35px;
  }
  hr {
    margin: 15px 0;
  }
  .cat {
    max-width: 300px;
  }
  .img-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 175px);
    gap: 10px;
    align-items: center;
    justify-content: center;
    .img-item {
      display: flex;
      width: 175px;
      height: 175px;
    }
  }
  .v-card {
    padding: 15px;
  }
  button {
    margin-right: 15px
  }
  .selected-img {
    margin: 15px 0;
    strong {
      span {
        color: gold;
      }
    }
  }
</style>