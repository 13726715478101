import { render, staticRenderFns } from "./ProductionImages.vue?vue&type=template&id=921a4ee6&scoped=true&"
import script from "./ProductionImages.vue?vue&type=script&lang=js&"
export * from "./ProductionImages.vue?vue&type=script&lang=js&"
import style0 from "./ProductionImages.vue?vue&type=style&index=0&id=921a4ee6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "921a4ee6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
installComponents(component, {VBtn,VCard,VChip,VFileInput,VIcon,VImg,VItem,VItemGroup})
