<template>
  <div>
    <h2 class="hline">Продукция</h2>

    <div class="selectProduct-wrapper">
      <v-btn
        title="вернуться назад"
        v-if="access.prodEdit"
        small
        color="#DC143C"
        style="margin-bottom: 25px"
        @click="prodEditCancel"
      >назад</v-btn>
      <div class="select-wrap" v-if="!access.prodEdit">
        <v-select
          class="selectProduct"
          :items="select"
          v-model="prodID"
          label="Выберите продукцию..."
          outlined
          dense
          @input="selectProd(prodID)"
        ></v-select>
        <v-btn @click="prodEdit()" :disabled="prodID == null" color="green">изменить</v-btn>
      </div>
    </div>
    
    <div class="prodEdit" v-if="access.prodEdit">

      <div class="itemName">
        <v-text-field
          label="Название"
          outlined
          dense
          v-model="itemName"
          :disabled="!access.itemName"
        ></v-text-field>
        <v-btn @click="access.itemName = true" v-if="!access.itemName">изменить</v-btn>
        <v-btn @click="itemNameSave()" v-if="access.itemName" color="green">Сохранить</v-btn>
      </div>

      <div class="prodImg-wrapper">
        <div class="wrap">
          <v-img
            v-if="prodID != null"
            class="prodIMG"
            :src="itemIMG"
          ></v-img>
        </div>
        <v-file-input
          v-if="access.itemIMG"
          class="fileInput"
          label="выберите изображение..."
          outlined
          dense
          show-size
          counter
          prepend-icon="mdi-camera"
          v-model="prodIMG"
          @change="prodImgInput(prodIMG)"
        ></v-file-input>
        <v-btn 
          :class="{imgMargin: !access.itemIMG}"
          v-if="!access.itemIMG"
          @click="access.itemIMG = true"
        >изменить</v-btn>
        <v-btn @click="itemIMGSave()" v-if="access.itemIMG" color="green" :disabled="prodIMG == null">Сохранить</v-btn>
      </div>
      
    </div>
    <div v-if="access.prodSaveBtn && !access.prodEdit">
      <div class="ckeditor">
        <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>
      </div>
      <hr>
      <div style="margin-top: 15px">
        <v-btn color="green" @click="prodTextUPD()">сохранить</v-btn>
      </div>
    </div>
    <ProductionImages 
      v-if="access.prodSaveBtn && !access.prodEdit" 
      :prodID="prodID"
      :messageOn="messageOn"
      :loadingWindow="loadingWindow"
    />
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Table from '@ckeditor/ckeditor5-table/src/table';
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar';
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties';
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties';
import Font from '@ckeditor/ckeditor5-font/src/font';
import FontFamily from '@ckeditor/ckeditor5-font/src/fontfamily';
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle';
import Indent from '@ckeditor/ckeditor5-indent/src/indent';
import {mapActions, mapGetters} from 'vuex'
import ProductionImages from '@/components/ProductionImages.vue'
export default {
  props: {
    messageOn: Function,
    loadingWindow: Function
  },
  components: {
    ProductionImages
  },
  data: () => ({
    access: {
      prodEdit: false,
      itemName: false,
      itemIMG: false,
      prodSaveBtn: false
    },
    imgName: '',
    prodID: null,
    itemIMG: '',
    itemName: '',
    prodIMG: null,
    editor: ClassicEditor,
    editorData: '',
    editorConfig: {
        /* language: 'ru', */
        plugins: [
            EssentialsPlugin,
            BoldPlugin,
            ItalicPlugin,
            LinkPlugin,
            ParagraphPlugin,
            Table,
            TableToolbar,
            TableProperties,
            TableCellProperties,
            Font,
            FontFamily,
            ListStyle,
            Indent
        ],

        toolbar: {
            items: [
                'bold',
                'italic',
                'link',
                'undo',
                'redo',
                '|',
                'fontSize',
                'fontColor',
                'fontBackgroundColor',
                'fontFamily',
                '|', 
                'outdent', 
                'indent', 
                '|',
                'bulletedList',
                'numberedList',
                '|',
                'insertTable'
            ]
        },
        indentBlock: {
            offset: 40,
            unit: 'px'
        },
        table: {
          contentToolbar: [
                'tableColumn', 'tableRow', 'mergeTableCells',
                'tableProperties', 'tableCellProperties'
            ],
        },
        fontSize: {
            options: [
                'default',
                9,
                11,
                13,
                17,
                19,
                21,
                25,
                27,
                30
            ]
        },
    }
  }),
  computed: {
    ...mapGetters(['getProdItem', 'getProd']),
    select: function() {
      let arr = new Array
      this.getProdItem.map(s => {
        arr.push({text: s.prod, value: s.id})
      })
      return arr
    },
  },
  watch: {
    prodID: function() {
      this.__prodID()
      this.__prod()
    }
  },
  methods: {
    ...mapActions(['prodTextUpdate', 'itemName_Save', 'itemIMG_Save']),
    __prodID() {
      if(this.prodID != null) {
        let img = this.getProdItem.find(i => i.id == this.prodID)
        this.imgName = img.img
        this.itemIMG = img.path + img.img
        this.itemName = this.getProdItem.find(i => i.id == this.prodID).prod
      } else {
        this.itemIMG, this.itemName = ''
      }
    },
    __prod() {
      if(this.prodID != null) {
        this.editorData = this.getProd.find(p => p.item_id == this.prodID).text
      }
    },
    selectProd() {
      this.access.prodSaveBtn = true
    },
    prodImgInput() {
      this.prodIMG != null ? this.itemIMG = URL.createObjectURL(this.prodIMG) : this.itemIMG = ''
    },
    prodEditCancel() {
      this.access.prodEdit = false
      this.access.itemName = false
      this.access.itemIMG = false
      this.prodIMG = null
      this.__prodID()
      this.__prod()
    },
    prodEdit() {
      this.access.prodEdit = true

    },
    async itemNameSave() {
      this.loadingWindow(true)
      let res = await this.itemName_Save({id: this.prodID, item: this.itemName})
      this.loadingWindow(false)
      this.access.itemName = false
      if (res.itemNameSave == 'ok') {
        this.messageOn('Название сохранено', 'green')
      } else {
        this.messageOn('[ошибка:] Ошибка сохранения!', 'red')
      }
    },
    async itemIMGSave() {
      this.loadingWindow(true)
      let response = await this.itemIMG_Save({id: this.prodID, imgName: this.imgName, file: this.prodIMG})
      this.access.itemIMG = false
      this.prodIMG = null
      this.__prodID()
      this.loadingWindow(false)
      if (response.itemIMGSave == 'ok') {
        this.messageOn('Изображение сохранено', 'green')
      } else {
        this.messageOn('[ошибка:] Ошибка сохранения!', 'red')
      }
    },
    async prodTextUPD() {
      this.loadingWindow(true)
      let response = await this.prodTextUpdate({
        id: this.prodID,
        txt: this.editorData
      })
      this.loadingWindow(false)
      console.log(response.prodTextUpdate)
      response.prodTextUpdate == 'ok' ? this.messageOn('текст обновлен', 'green') : this.messageOn('[!ошибка] обновления текста', 'red')
    }
  }
}
</script>

<style lang="scss" scoped>
  /* .ckeditor {
    color: black;
  } */
  .selectProduct-wrapper {
    .select-wrap {
      display: flex;
      gap: 10px;
      .selectProduct {
        max-width: 500px;
      }
      button {
        margin-top: 2px;
      }
    }
  }
  .prodEdit {
    /* display: flex;
    gap: 10px;
    flex-wrap: wrap; */
    .itemName {
      max-width: 500px;
      display: flex;
      gap: 10px;
      button {
        margin-top: 2px;
      }
    }
    .prodImg-wrapper {
      .wrap {
        border: 1px solid #ffffff;
        padding: 5px;
        border-radius: 3px;
        max-width: 170px;
        .prodIMG {
          width: 100%;
          border-radius: 3px;
        }
      }
      .fileInput {
        max-width: 500px;
        margin: 19px 0 0 0;
      }
      button {
        margin: -7px 10px 0 0;
      }
      .imgMargin {
        margin-top: 15px;
      }
    }
  }
  .ckeditor {
    color: #000000;
    margin-bottom: 15px;
  }
</style>